exports.shouldUpdateScroll = ({
    routerProps,
    prevRouterProps,
    getSavedScrollPosition
  }) => {
    const currentPosition = getSavedScrollPosition(routerProps.location)
    const queriedPosition = getSavedScrollPosition(prevRouterProps.location)

    // console.log(prevRouterProps.location);
    // console.log(routerProps.location);

    if ( 
        ( routerProps.location.pathname.includes("/case-studies/filter") 
        || routerProps.location.pathname.includes("/case-studies/page") )
        
        && 

        ( prevRouterProps.location.pathname.includes("/case-studies/filter")
        || prevRouterProps.location.pathname.includes("/case-studies/page") 
        || prevRouterProps.location.pathname == "/case-studies/" )
    ) {
        window.scrollTo(...(queriedPosition || [0, prevRouterProps.location.state.fromFilter] ))
    } else {
        window.scrollTo(...(currentPosition || [0, 0]))
    }
   
    return false
  }